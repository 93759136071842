import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUserById } from "../../Services/Pointeuse/UsersApi";
import { useTranslation } from "react-i18next";

function SidebarUser() {
  const [data, setdata] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const { t, i18n } = useTranslation();

  // Récupérez la langue sélectionnée du stockage local au chargement initial de la composante
  //  useEffect(() => {
  //   const savedLanguage = localStorage.getItem('selectedLanguage');
  //   if (savedLanguage) {
  //     i18n.changeLanguage(savedLanguage);
  //     setSelectedLanguage(savedLanguage);
  //   }
  // }, [i18n]);
  useEffect(() => {
    // Toggle Sidebar
    $(document).on("click", '[data-toggle="sidebar"]', function (event) {
      event.preventDefault();
      $(".app").toggleClass("sidenav-toggled");
    });

    $(".app-sidebar").hover(
      function () {
        if ($(".app").hasClass("sidenav-toggled")) {
          $(".app").addClass("sidenav-toggled1");
        }
      },
      function () {
        if ($(".app").hasClass("sidenav-toggled")) {
          $(".app").removeClass("sidenav-toggled1");
        }
      }
    );

    // Activate sidebar slide toggle
    $("[data-toggle='slide']").on("click", function (e) {
      var $this = $(this);
      var checkElement = $this.next();
      var animationSpeed = 300,
        slideMenuSelector = ".slide-menu";
      if (checkElement.is(slideMenuSelector) && checkElement.is(":visible")) {
        checkElement.slideUp(animationSpeed, function () {
          checkElement.removeClass("open");
        });
        checkElement.parent("li").removeClass("is-expanded");
      } else if (checkElement.is(slideMenuSelector) && !checkElement.is(":visible")) {
        var parent = $this.parents("ul").first();
        var ul = parent.find("ul:visible").slideUp(animationSpeed);
        ul.removeClass("open");
        var parent_li = $this.parent("li");
        checkElement.slideDown(animationSpeed, function () {
          checkElement.addClass("open");
          parent.find("li.is-expanded").removeClass("is-expanded");
          parent_li.addClass("is-expanded");
        });
      }
      if (checkElement.is(slideMenuSelector)) {
        e.preventDefault();
      }
    });

    // Activate sidebar slide toggle
    $("[data-toggle='sub-slide']").on("click", function (e) {
      var $this = $(this);
      var checkElement = $this.next();
      var animationSpeed = 300,
        slideMenuSelector = ".sub-slide-menu";
      if (checkElement.is(slideMenuSelector) && checkElement.is(":visible")) {
        checkElement.slideUp(animationSpeed, function () {
          checkElement.removeClass("open");
        });
        checkElement.parent("li").removeClass("is-expanded");
      } else if (checkElement.is(slideMenuSelector) && !checkElement.is(":visible")) {
        var parent = $this.parents("ul").first();
        var ul = parent.find("ul:visible").slideUp(animationSpeed);
        ul.removeClass("open");
        var parent_li = $this.parent("li");
        checkElement.slideDown(animationSpeed, function () {
          checkElement.addClass("open");
          parent.find("li.is-expanded").removeClass("is-expanded");
          parent_li.addClass("is-expanded");
        });
      }
      if (checkElement.is(slideMenuSelector)) {
        e.preventDefault();
      }
    });

    // Activate sidebar slide toggle
    $("[data-toggle='sub-slide2']").on("click", function (e) {
      var $this = $(this);
      var checkElement = $this.next();
      var animationSpeed = 300,
        slideMenuSelector = ".sub-slide-menu2";
      if (checkElement.is(slideMenuSelector) && checkElement.is(":visible")) {
        checkElement.slideUp(animationSpeed, function () {
          checkElement.removeClass("open");
        });
        checkElement.parent("li").removeClass("is-expanded");
      } else if (checkElement.is(slideMenuSelector) && !checkElement.is(":visible")) {
        var parent = $this.parents("ul").first();
        var ul = parent.find("ul:visible").slideUp(animationSpeed);
        ul.removeClass("open");
        var parent_li = $this.parent("li");
        checkElement.slideDown(animationSpeed, function () {
          checkElement.addClass("open");
          parent.find("li.is-expanded").removeClass("is-expanded");
          parent_li.addClass("is-expanded");
        });
      }
      if (checkElement.is(slideMenuSelector)) {
        e.preventDefault();
      }
    });

    //Activate bootstrip tooltips

    // ______________Active Class
    $(document).ready(function () {
      $(".app-sidebar li a").each(function () {
        var pageUrl = window.location.href.split(/[?#]/)[0];
        if (this.href == pageUrl) {
          $(this).addClass("active");
          $(this).parent().addClass("is-expanded");
          $(this).parent().parent().prev().addClass("active");
          $(this).parent().parent().addClass("open");
          $(this).parent().parent().prev().addClass("is-expanded");
          $(this).parent().parent().parent().addClass("is-expanded");
          $(this).parent().parent().parent().parent().addClass("open");
          $(this).parent().parent().parent().parent().prev().addClass("active");
          $(this).parent().parent().parent().parent().parent().addClass("is-expanded");
          $(this).parent().parent().parent().parent().parent().parent().prev().addClass("active");
          $(this).parent().parent().parent().parent().parent().parent().parent().addClass("is-expanded");
        }
      });
    });
  }, []);
  useEffect(() => {
    // document.body.classList.add("sidebar-collapse");
    getUserById(localStorage.getItem("id")).then((res) => {
      setdata(res.data);
    });
  }, []);
  return (
    <aside className="app-sidebar">
      <div className="app-sidebar__logo">
        <Link to="/user" className="header-brand">
          {/* <img src="assets/images/brand/logo2.png" className="header-brand-img desktop-lgo" alt="Cleges" /> */}
          {/* <p className="header-brand-img dark-logo" style={{ color: "white", fontSize: 20 }}>
            Pointeuse
          </p> */}
          <img src="assets/images/brand/logo2.png" className="header-brand-img dark-logo" style={{ width: "125px" }} alt="Cleges" />
          <img src="assets/images/brand/logo1.png" className="header-brand-img mobile-logo" alt="Cleges" />
          {/* <img src="assets/images/brand/logo2.png" className="header-brand-img darkmobile-logo" alt="Cleges" /> */}
        </Link>
      </div>
      <div className="app-sidebar3">
        <div className="app-sidebar__user">
          <div className="dropdown user-pro-body text-center">
            <div className="user-pic">
              <img
                src={data.img == null || data.img == "" ? "assets/images/users/avatar.png" : localStorage.getItem("baseUrl") + data.img}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "assets/images/users/avatar.png";
                }}
                alt="user-img"
                className="avatar-xxl rounded-circle mb-1"
              />
            </div>
            <div className="user-info">
              <h5 className=" mb-2">
                {data.nom} {data.prenom}
              </h5>
              <span className="text-muted app-sidebar__user-name text-sm">{data.fonction}</span>
            </div>
          </div>
        </div>
        <ul className="side-menu">
          {/* <li className="side-item side-item-category mt-4">{t("Dashboard")}</li> */}






          {/* <li className="slide">
            <Link to="/admin/support" className="side-menu__item" data-toggle="slide">
              <i className="feather feather-headphones sidemenu_icon" />
              <span className="side-menu__label">{t("Support")}</span>
            </Link>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/support" className="slide-item">
                  {t("support")}
                </Link>
              </li>
            </ul>
          </li> */}
          <li className="slide">
            <a className="side-menu__item" data-toggle="slide" href="/user/teleTravailcalendar">
              <i className="feather feather-clipboard sidemenu_icon" />
              <span className="side-menu__label">{t("Télétravail")}</span>
             
            </a>

          </li>
          <li className="side-item side-item-category">{t("Département support")}</li>
          <li className="slide">
            <a className="side-menu__item" data-toggle="slide" href="#">
              <i className="feather feather-clipboard sidemenu_icon" />
              <span className="side-menu__label">{t("support des clients")}</span>
              <i className="angle fa fa-angle-right" />
            </a>
            <ul className="slide-menu">
              <li>
                <Link to="/user/supportManagement" className="slide-item">
                  {t("support")}
                </Link>
              </li>
              <li>
                <Link to="/user/satisfaction" className="slide-item">
                  {t("satisfaction")}
                </Link>
              </li>
              <li>
                <Link to="/user/reunion" className="slide-item">
                  {t("Reunion")}
                </Link>
              </li>
            </ul>
          </li>


          <li className="side-item side-item-category">{t("Gestion projet")}</li>

          <li className="slide">
            <a className="side-menu__item" data-toggle="slide" href="#">
              <i className="feather feather-clipboard sidemenu_icon" />
              <span className="side-menu__label">{t("Tâches et projets")}</span>
              <i className="angle fa fa-angle-right" />
            </a>
            <ul className="slide-menu">
              <li>
                <Link to="/user/task" className="slide-item">
                  {t("Tâches")}
                </Link>
              </li>

            </ul>
          </li>







          {/* <li className="slide" style={{overflow:"auto",maxHeight:"300px"}}>
            <a className="side-menu__item" data-toggle="slide" href="#">
              <i className="fa fa-copy sidemenu_icon" />
              <span className="side-menu__label">{t("Document de vente")}</span>
              <i className="angle fa fa-angle-right" />
            </a>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/facturevente" className="slide-item">
                  {t("Facture")}
                </Link>
              </li>
            </ul>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/blvente" className="slide-item">
                  {t("Bon de livraison")}
                </Link>
              </li>
            </ul>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/devisvente" className="slide-item">
                  {t("Devis")}
                </Link>
              </li>
            </ul>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/commandevente" className="slide-item">
                  {t("Commande")}
                </Link>
              </li>
            </ul>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/listabonnement" className="slide-item">
                  {t("abonnement")}
                </Link>
              </li>
            </ul>
          </li> */}




          {/* <li className="slide" style={{overflow:"auto",maxHeight:"300px"}}>
            <a className="side-menu__item" data-toggle="slide" href="#">
              <i className="fa fa-copy sidemenu_icon" />
              <span className="side-menu__label">{t("Document d'achat")}</span>
              <i className="angle fa fa-angle-right" />
            </a>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/factureachat" className="slide-item">
                  {t("Facture")}
                </Link>
              </li>
            </ul>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/blachat" className="slide-item">
                  {t("Bon de livraison")}
                </Link>
              </li>
            </ul>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/devisachat" className="slide-item">
                  {t("Devis")}
                </Link>
              </li>
            </ul>
            <ul className="slide-menu">
              <li>
                <Link to="/admin/commandeachat" className="slide-item">
                  {t("commande")}
                </Link>
              </li>
            </ul>
          </li> */}


        </ul>

        {/* khkjklj */}

      </div>
    </aside>
  );
}

export default SidebarUser;
