import React, { useEffect, useState, useCallback, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import styled from "styled-components";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  addTeletravail,
  deleteTeletravailById,
  getTeletravaiByCodeAndDate,
} from "../../Services/Pointeuse/TeletravailApi";
import { t } from "i18next";
import CustomSyncLoader from "../Others/CustomSyncLoader";

const Event = styled.div`
  display: inline-flex;
  font-weight: bold;
  vertical-align: top;
`;

const ImageEvent = styled.div`
  flex: 1;
  height: 100%;
`;

const TextEvent = styled.div`
  flex: 1;
  text-align: center;
  margin-left: 5px;
  margin-top: 4px;
`;

const TeleTravailCalendar = () => {
  const [events, setEvents] = useState([]);
  const calendarRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const confirmDeletion = useCallback((eventId) => {
    return swal({
      title: "Êtes-vous sûr ?",
      text: "Voulez-vous supprimer la réservation !",
      icon: "warning",
      buttons: {
        cancel: t("Cancel"),
        confirm: {
          text: t("Supprimer"),
          value: true,
        },
      },
      dangerMode: true,
    });
  }, []);

  const handleEventClick = async (e) => {
    const idEvent = e.event._def.publicId;
    const userid = localStorage.getItem("id");
    const findEvent = events.find((item) => item.id === parseInt(idEvent));

    if (findEvent) {
      if (
        parseInt(findEvent.user_id) === parseInt(userid) &&
        findEvent.type === "demande"
      ) {
        const res = await confirmDeletion(idEvent);
        if (res) {
          setLoading(true)
         const respdelete= await deleteTeletravailById(parseInt(idEvent));
         if(respdelete &&respdelete.status===200){
          setLoading(false)
          setEvents(events.filter((item) => item.id !== idEvent));
          swal({
            title: "Supprimée!",
            text: "Votre réservation a été supprimée.",
            icon: "success",
          }).then((res)=>{
            window.location.reload()
          });
         }
          
        }
      } else {
        const fonctionuser = localStorage.getItem("fonction");
        if (
          fonctionuser.toLowerCase() === findEvent.fonction.toLowerCase() &&
          !(findEvent.valide === 0 && findEvent.type === "teletravail")
        ) {
          toast.warning("Vous ne pouvez pas réserver ce jour");
          window.location.reload()
        } else {
          handleDateClick({ dateStr: findEvent.start });
        }
      }
    }
  };

  const handleDateClick = async (e) => {
    const findEvent = events.find((event) => {
      const eventDate = new Date(event.start).toISOString().split("T")[0];
      return eventDate === e.dateStr;
    });

    const userid = localStorage.getItem("id");

    if (findEvent) {
      const idEvent = parseInt(findEvent.id);

      if (parseInt(findEvent.user_id) === parseInt(userid)) {
        if (findEvent.valide === 0 && findEvent.type === "demande") {
          const res = await confirmDeletion(idEvent);
          if (res) {
            setLoading(true)
           const resdelete = await deleteTeletravailById(idEvent);
           if(resdelete && resdelete.status===200){
            setLoading(false)

            setEvents(
              events.filter((item) => parseInt(item.id) !== parseInt(idEvent))
            );
            swal({
              title: "Supprimée!",
              text: "Votre réservation a été supprimée.",
              icon: "success",
            }).then(() => {
              // Mettre à jour l'état des événements sans recharger la page
              alert('ok')
              setEvents(events.filter((item) => item.id !== idEvent));
            });
           }
            
            
          }
        } else {
          swal({
            title: "La demande a déjà été traitée",
            icon: "warning",
          });
          return;
        }
      } else {
        const fonctionuser = localStorage.getItem("fonction");
        if (
          fonctionuser?.toLowerCase() === findEvent.fonction.toLowerCase() &&
          !(findEvent.valide === 0 && findEvent.type === "teletravail")
        ) {
          toast.warning("Vous ne pouvez pas réserver ce jour");
          window.location.reload()

        }
      }
    } else {
      const date = new Date(e.dateStr);
      date.setDate(date.getDate() + 1);

      const newTT = {
        code_generated: localStorage.getItem("code_generated"),
        user_id: localStorage.getItem("id"),
        motif: "TT",
        type: "demande",
        date_debut: e.dateStr,
        date_fin: date?.toISOString()?.split("T")[0],
      };

      try {
        setLoading(true)
        const res = await addTeletravail(newTT);
        setEvents([...events, newTT]);
        if (res && res.status === 200) {
          setLoading(false)

          toast.success("Télétravail ajouté");
          window.location.reload()

        } else {
          toast.error("Une erreur est survenue");
        }
      } catch (error) {
        toast.error("Erreur: " + error.message);
      }
    }
  };

  const handleDatesSet = async (e) => {
    const datedebut = e.startStr.split("T")[0];
    const dateFin = e.endStr.split("T")[0];
    const res = await getTeletravaiByCodeAndDate(datedebut, dateFin);
    const newEvents = res.data.map((d) => ({
      title: `${d.nom} ${d.prenom}`,
      start: d.date_debut,
      end: d.date_fin,
      color: getStatusColor(d.type, d.valide),
      allDay: true,
      id: d.id,
      valide: d.valide,
      type: d.type,
      fonction: d.fonction,
      user_id: d.user_id,
      image: "assets/images/users/avatar.png",
    }));
    setEvents(newEvents);
  };

  const getStatusColor = (type, valide) => {
    if (type === "demande" && valide === 0) {
      return "blue";
    } else if (type === "teletravail" && valide === 0) {
      return "red";
    } else if (type === "teletravail" && valide === 1) {
      return "green";
    }
  };

  const getStatusIcon = (type, valide) => {
    if (type === "demande" && valide === 0) {
      return (
        <span>
          <i className="fa fa-star" style={{ color: "white" }} />{" "}
          {t("en cours")}
        </span>
      );
    } else if (type === "teletravail" && valide === 0) {
      return (
        <span style={{ color: "white" }}>
          <i
            className="feather feather-x-circle"
            style={{ color: "white" }}
          />{" "}
          {t("refusé")}
        </span>
      );
    } else if (type === "teletravail" && valide === 1) {
      return (
        <span>
          <i className="feather feather-check-circle" style={{ color: "white" }} />
          {t("accepté")}
        </span>
      );
    }
  };

  const renderEventContent = (eventInfo) => {
    const findEvent = events.find(
      (item) => parseInt(item.id) === parseInt(eventInfo.event._def.publicId)
    );

    return (
      <Event>
        <ImageEvent>
          <img
            className="avatar"
            src={eventInfo.event._def.extendedProps.image}
            alt={eventInfo.event.title}
          />
        </ImageEvent>
        <TextEvent>
          <h5>{eventInfo.event.title}</h5>
          {getStatusIcon(findEvent?.type, findEvent?.valide)}
        </TextEvent>
      </Event>
    );
  };

  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.updateSize();
    }
  }, [events]);

  return (
    <div className="row">
      <div className="col-xl-12 col-md-12 col-lg-12">
        <div className="card">
          <div className="card-header border-0">
            <h4 className="card-title">{t("Calendrier Tele travail")}</h4>
          </div>
          {loading && <CustomSyncLoader></CustomSyncLoader>}
          <div className="table-responsive">
            <div className="row" style={{ height: "1000px" }}>
              <div className="col-12">
                <FullCalendar
                  ref={calendarRef}
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  weekends={false}
                  dateClick={handleDateClick}
                  eventClick={handleEventClick}
                  events={events}
                  eventContent={renderEventContent}
                  datesSet={handleDatesSet}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeleTravailCalendar;
