import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAutorisationByUserId } from "../../Services/Pointeuse/AutorisationApi";
import { getCongeByUserCodeId, getCountCongeByUserCodeId } from "../../Services/Pointeuse/CongeApi";
import { getDepalacementByUserId } from "../../Services/Pointeuse/DeplacementApi";
import { getPersonnelById } from "../../Services/Pointeuse/PersonnelApi";
import { getPointageByUserId } from "../../Services/Pointeuse/PointageApi";
import { getQuestionnaireByUserId } from "../../Services/Pointeuse/QuestionnaireApi";
import { getRetardByUserId } from "../../Services/Pointeuse/RetardApi";
import {
  filterAutorisationByMonth,
  filterCongesByMonth,
  filterDeplacementByMonth,
  filterPointageByMonth,
  filterQuestionnaireByMonth,
  filterRetardByMonth,
  getCalculateAbsente,
  getLastPointage,
} from "../../Utils/GlobalTableUtils";
import ImageComponent from "../Styles/ImageComponent";
import AddPointageManquant from "./AddPointageManquant";

function SinglePointage({
  user,
  currentDate,
  totalConge,
  cp,
  workDay,
  monthRetard,
  breakTime,
  getData,
  dataRetards,
  dataPointages,
  dataDeplacements,
  dataConges,
}) {
  const [retards, setretards] = useState(null);
  const [pointages, setpointages] = useState(null);
  const [conges, setConges] = useState(null);
  const [deplacements, setdeplacements] = useState(null);
  const [pSorties, setpSorties] = useState(null);
  const [contConge, setcontConge] = useState("0");
  const [dateFinContrat, setdateFinContrat] = useState(null);
  const [userId, setuserId] = useState(null);
  const [isOpened, setisOpened] = useState(false);

  useEffect(() => {
    //filter dataretard byid
    const filterById = dataRetards.filter((el) => el.user_id == user.id);
    setretards(filterRetardByMonth(filterById, currentDate));
  }, [user, currentDate, totalConge, cp, breakTime]);

  useEffect(() => {
    //TODO change to list
    // const filterById = dataPointages.filter((el) => el.user_code_id == user.id);
    // setpointages(filterPointageByMonth(filterById, currentDate));
    getPointageByUserId(user.id).then((res) => {
      const filtredPointage = filterPointageByMonth(res?.data, currentDate);
      setpointages(filtredPointage);
    });
  }, [user, currentDate, totalConge, cp, breakTime]);

  useEffect(() => {
    const filterById = dataConges.filter((el) => el.user_code_id == user.id);
    setConges(filterCongesByMonth(filterById, currentDate));
  }, [user, currentDate, totalConge, cp, breakTime]);

  useEffect(() => {
    const filterById = dataDeplacements.filter((el) => el.user_code_id == user.id);
    setdeplacements(filterDeplacementByMonth(filterById, currentDate));
  }, [user, currentDate, totalConge, cp, breakTime]);

  useEffect(() => {
    //TODO CHANGE TO LIST
    getCountCongeByUserCodeId(user.id).then((res) => {
      var key = Object.entries(res.data[0]);
      key[0][1] && setcontConge(key[0][1]);
    });
  }, [user, currentDate, totalConge, cp, breakTime]);

  /**
   * It sets the userId to the value passed in and opens the modal.
   * @param value - The value of the selected pointage.
   */
  const handleSetPointageManquant = (value) => {
    setuserId(value);
    setisOpened(true);
  };

  /**
   * *This function is used to open the modal.*
   */
  const handleOpen = () => {
    setisOpened(false);
  };

  return (
    <>
      <tr key={user.id}>
        <td>#{user.id}</td>
        <td>
          <div className="d-flex">
            <ImageComponent atr={"avatar avatar-md brround mr-3"} picture={user.img}></ImageComponent>
            <div className="mr-8 mt-0 mt-sm-1 d-block">
              <h6 className="mb-1 fs-14">
                {user.nom} {user.prenom}
              </h6>
              <p className="text-muted mb-0 fs-12">{user.email}</p>
            </div>
          </div>
        </td>
        {/* <td>
        <span className="badge badge-danger-light">Absent</span>
      </td> */}
        {/* Présence */}
        {pointages && (
          <td>
            <span className="badge badge-success-light">{pointages.length}</span>
          </td>
        )}
        {/* absent */}
        {/* à corriger */}
        {pointages && conges && deplacements && (
          <td>
            <span className="badge badge-danger-light">{getCalculateAbsente(pointages, currentDate, conges.length, cp, deplacements.length)}</span>
          </td>
        )}
        {/* retard */}
        {retards && (
          <td>
            <span className="badge badge-warning-light">{retards?.length}</span>
          </td>
        )}

        {/* pointage sortie */}
        {pointages && (
          <td>
            {getLastPointage(pointages)}
            {/* /{pointages.length} */}
          </td>
        )}
        {/* <td>
        <span className="badge badge-danger">Not Marked</span>
      </td> */}
        <td>
          <div className="d-flex">
            {/* <label className="custom-control custom-checkbox-md">
            <input
              type="checkbox"
              className="custom-control-input-success"
              name="example-checkbox1"
              defaultValue="option1"
            />
            <span className="custom-control-label-md success" />
          </label> */}
            <Link
              to="#"
              className="action-btns1 bg-light"
              data-toggle="modal"
              data-target="#presentmodal1"
              onClick={() => handleSetPointageManquant(user.id)}
            >
              <i className="feather feather-eye  text-primary" data-toggle="tooltip" data-original-title="View" />
            </Link>
          </div>
        </td>
      </tr>
      {isOpened && (
        <AddPointageManquant userId={userId} currentDate={currentDate} isOpened={isOpened} handleOpen={handleOpen} refreshData={getData} />
      )}
    </>
  );
}

export default SinglePointage;
