import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getToken, getSession } from "../../Utils/SessionUtils";
import { t } from "i18next";

const api_url = process.env.REACT_APP_API_BASE_URL_V1;
const config = {
  headers: { Authorization: `Bearer ${getToken()}` },
};

/**
 * Get all teletravail by code
 * @returns The response is an array of objects.
 */
export async function getAllTeletravailByCode() {
  try {
    const response = await axios.get(api_url + "teletravail/code/" + localStorage.getItem("code_generated"), config);

    return response;
  } catch (error) {
    console.error(error);
  }
}
export async function getTeletravaiByCodeAndDate(datedebut,datefin) {
  try {
    const response = await axios.get(api_url + "teletravail/code/" + localStorage.getItem("code_generated")+'/'+datedebut+'/'+datefin, config);

    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 *add teletravail
 * @returns The response is an array of objects.
 */
export async function addTeletravail(data) {
  try {
    const response = await axios.post(api_url + "teletravail/",data, config);

    return response;
  } catch (error) {
    console.error(error);
  }
}

/**
 * Accept a teletravail request
 * @param id - id of the leave
 * @returns The response from the server.
 */
export async function acceptTeletravail(id) {
  const credentiel = {
    valide: "1",
    type: "teletravail",
    id: id,
  };
  try {
    const response = await axios.put(api_url + "teletravail", credentiel, config);
    // console.log(response);
    // toast.success("Congé accepté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * Refuse a teletravail
 * @param id - the id of the leave request
 * @returns The response from the server.
 */
export async function refuseTeletravail(id) {
  const credentiel = {
    valide: "0",
    type: "teletravail",
    id: id,
  };
  try {
    const response = await axios.put(api_url + "teletravail", credentiel, config);
    // console.log(response);
    // toast.success("Congé accepté");
    return response;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      toast.error(t("error server"));
      console.log(error.response.data);
      console.log(error.response.message);
      console.log(error.response.status);
      console.log(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the
      // browser and an instance of
      // http.ClientRequest in node.js
      toast.error(t("error request"));
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log(t("Error"), error.message);
    }
    console.log(error.config);
    return error;
  }
}

/**
 * It deletes a teletravail by id.
 * @param id - The id of the teletravail to delete.
 * @returns The response is an object with the following structure:
 * ```json
 * {
 *   "data": {
 *     "id": "1",
 *     "titre": "titre",
 *     "description": "description",
 *     "dateDebut": "2020-01-01T00:00:00.000Z",
 *     "dateFin": "
 */
export async function deleteTeletravailById(id) {
  try {
    const response = await axios.delete(api_url + "teletravail/" + id, config);
    return response;
  } catch (error) {
    console.error(error);
  }
}
